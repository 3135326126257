import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class MountFiltro extends Vue {
  @Prop({required: true}) form!: Array<any>
  @Prop({default: false}) isLoading!: boolean

  modelForm: any = {}  

  mounted() {
    this.setSelectsDefaultValues();
  }

  setSelectsDefaultValues(){
    const aux: any = {}
    this.form.map( (arr) => {
      arr.map( (input: any) => {
        if(input.type == 'select'){
          aux[input.name] = ''
        }
      })
    })
    this.modelForm = aux
  }

  clickBtnLimparFiltro(){
    this.modelForm = {}
    this.setSelectsDefaultValues();
  }

  clickBtnBuscar(){
    const queryFiltro = this.mountUrlQueryFiltro();
    const form = Object.assign({}, this.modelForm);
    for(const key in form){
      if(form[key] instanceof Date){
        form[key] = this.convertDataHourPtBr2(form[key]);
      }
      if(form[key] == ''){
        delete form[key];
      }
    }
    this.$emit('onClickBtnBuscar', queryFiltro, form)
  }

  today = new Date()
  minDate = new Date(this.today.getFullYear() - 1, this.today.getMonth(), this.today.getDate())
  maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate())


  // Converte objeto Date() para string en-us
  parseDateEnUs(date: Date): string {		
    const day = date.getDate().toString().length < 1 ? `0${date.getDate()}` : date.getDate()
    const month = date.getMonth().toString().length < 1 ? `0${date.getMonth()+1}` : date.getMonth()+1
    const year = date.getFullYear();
    return `${year}-${month}-${day}`
  }

  convertDataHourPtBr2(strData: string){
    const data = new Date(strData),
    dia  = data.getDate().toString(),
    diaF = (dia.length == 1) ? '0'+dia : dia,
    mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = (mes.length == 1) ? '0'+mes : mes,
    anoF = data.getFullYear();
    return anoF+"-"+mesF+"-"+diaF;
  }

  mountUrlQueryFiltro(){
    let str  = '';
    let i = 1;
    for(const key in this.modelForm){
      if( this.modelForm[key] != '' ){
        let inputConfig: any = {};
        this.form.find( (line) => {
          inputConfig = line.find( (input: any) => {
            return input.name == key;
          })
          if(typeof inputConfig === 'object'){
            return true;
          }
        })
        if(inputConfig.type == 'date'){
          if(inputConfig.isLike){
            str = str + key + '=%25'+ this.parseDateEnUs(this.modelForm[key])+'%25';
          }
          else{
            str = str + key + '='+ this.parseDateEnUs(this.modelForm[key]);
          }
        }
        else{
          if(inputConfig.isLike){
            str = str + key + '=%25' + this.modelForm[key]+'%25';
          }
          else{
            str = str + key + '=' + this.modelForm[key];
          }
        }
        // console.log(this.modelForm[key] instanceof Date);
        // this.modelForm
        // if(key == 'objeto'){
        //   str = str + 'objeto_like' + '=' + this.modelForm[key];
        // }
        // else if(key == 'titulo'){
        //   str = str + 'titulo_like' + '=' + this.modelForm[key];
        // }
        // else{
          // str = str + key + '=' + this.modelForm[key];
        // }
        // adiciona caracter & na query
        if( i >= 1 && i < Object.keys(this.modelForm).length){
          str = str + '&'
        }
      }
      i = i + 1;
    }
    return str;
  }

}
  