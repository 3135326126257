import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import posts from '@/modules/posts/services/posts';
import content from '@/services/content';
import utils from '@/utils';
import MountFiltro from '@/components/mountFiltro/mountFiltro.vue';
import {
  Icons
} from '@/components/base';
  
@Component({
  components: {
    Icons,
    MountFiltro
  }
})
export default class ListAllPosts extends Vue {
  @Prop({default: 'noticia'}) postType!: string;

  isLoading = true;
  showFiltro = true;
  list = [];
  pageNow = 1;
  pagination = {
    count_total: 0,
    pages: 0,
    per_page: 10,
  };
  query: any = {
    page: '1',
    per_page: '10',
  }

  get isMobile(): boolean{
    return (this.$store.getters['screen/getWidth'] <= 460) ;
  }

  getPostUrlImage = content.getThumbnail
  ajustDate = utils.date.strDatetimeUSAToBR

  mounted() {
    this.getAllPosts(this.query);
  }

  // page=2&count=10&s=aaa&date_query[after]=2021-010-11&date_query[before]=2021-010-19

  getAllPosts(queryObj: any){
    const query = utils.queryURL.jsonToURLQuery(queryObj);
    this.isLoading = true
    window.scrollTo(0, 0);
    posts.sendGetAllPostsByType(this.postType, query)
      .then( async (response) => {
        this.list = response.data;
        this.pagination.count_total = response.headers['x-wp-total'];
        this.pagination.pages = response.headers['x-wp-totalpages'];
        // this.query = queryObj;
      })
      .finally( () => this.isLoading = false );
  }

  onClickBtnBuscar(queryStr: any, queryObj: any){
    console.log(queryStr);
    console.log(queryObj);
    if(Object.keys(queryObj).length == 0){
      this.query = {
        page: '1',
        count: '10',
      }
      this.pageNow = 1;
    }
    else if(this.query.page != '1'){
      this.query.page = '1'
      this.pageNow = 1;
    }
    const query = {...this.query, ...queryObj};
    // console.log(this.query);
    this.getAllPosts(query);
    // 
  }

  changePagination(page: string){
    this.query.page = page;
    this.getAllPosts(this.query);
  }

  clickGoPost(slug: string){
    if(this.postType == 'posts'){
      this.$router.push('/posts/'+slug)
    }
    else{
      this.$router.push(`/posts/${this.postType}/${slug}`)
    }
  }

  @Watch('postType', { 
    immediate: false, deep: false 
  })
  metaChange() {
    this.getAllPosts(this.query);
  }

  formFiltro = [
    [
      {
        size: 8,
        name: 's',
        label: 'Palavras chaves',
        type: 'text',
        placeholder: 'Palavras chaves'
      },
      {
        size: 2,
        name: 'date_query[after]',
        label: 'Depois de',
        type: 'date',
        placeholder: 'Depois de'
      },
      {
        size: 2,
        name: 'date_query[before]',
        label: 'Antes de',
        type: 'date',
        placeholder: 'Antes de'
      }
    ]
  ]

}
  