import http from '@/services/api';
import { mountUrlWP } from '@/utils/mountURL'
import content from '@/services/content';


export function sendGetAllPosts(type: string, query = ''): Promise<any> {
  // /get_posts/?post_type=boletim&orderby=date&order=desc&page=1&count=10
  let url = '';
  if(type == 'boletim'){
    url = mountUrlWP('/get_posts/?post_type=boletim&orderby=date&order=desc');
  }
  else{
    url = mountUrlWP('/posts?_embed');
  }
  url = (query != '') ? url +'&'+ query : url;
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export function sendGetAllPostsByType(type: string, query = ''): Promise<any> {
  let url = mountUrlWP(`/${type}?_embed`);
  url = (query != '') ? url +'&'+ query : url;
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export function getThumbnail(post: any) {
  return '';
  // return content.getPostUrlImage(post);
}

export default {
  sendGetAllPosts,
  getThumbnail,
  sendGetAllPostsByType
}